import React, { useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { getParentSlugOfId } from "../helper/Data";

const OldCat: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Extract search query from the URL
    const searchParams = new URLSearchParams(location.search);
    const oldCat = searchParams.get('category') || '';

    const { trackPageView } = useMatomo();

    useEffect(() => {
        trackPageView({
            href: window.location.href, // URL of the current page
        });
        const categories = localStorage.getItem('categories');
        if (categories) {
            let oldCatParId = -1;
            JSON.parse(categories).forEach((item: any) => {
                if (item.slug === oldCat) {
                    oldCatParId = item.parent_id;
                }
            });

            if (oldCatParId <= 0) {
                navigate('/notfound');
            }
            else{
                const parentSlug = getParentSlugOfId(oldCatParId);
                navigate(`/${parentSlug}/${oldCat}`);
            }
        }
        else{
            navigate('/notfound');
        }
    }, [location]);

    return (
        <ion-page className="sg-body">
        </ion-page>
    )
}

export default OldCat;
