import React from "react";
import { Link } from 'react-router-dom';

export default function CategoryCard(props: { currentUrl: string, name: string, slug: string, isParent: boolean }) {
    const { currentUrl, name, slug, isParent } = props;
    return (
        <>
            {isParent ?
                <Link
                    to={`/${slug}`}
                    style={{ backgroundImage: `url("/icons/${slug}.svg")` }}
                    className="kachel main-category">
                    {name}
                </Link>
                :
                <Link
                    style={{ backgroundImage: `url("/icons/${slug}.svg")` }}
                    className="kachel child-category"
                    to={`/${currentUrl}/${slug}`}>
                    {name}
                </Link>
            }
        </>
    )
}

