import React, { useEffect, useState } from "react";
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import pageTransition from "../components/pageTransition";
import StartSettings from "../components/startSettings";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MetaHelmet from "../components/metaHelmet";

const NotFound: React.FC = () => {
    const { t } = useTranslation();

    // Load settings
    const [settingsSet] = useState<boolean>(() => !!localStorage.getItem('settings-set'));
    const [language] = useState(localStorage.getItem('i18nextLng') || 'en');

    const location = useLocation();
    const { trackPageView } = useMatomo();
  
    useEffect(() => {
      trackPageView({
        href: window.location.href, // URL der aktuellen Seite
      });
    }, [location]);   

    return (
        !settingsSet ? (
            <ion-page class="sg-body">
                <StartSettings />
            </ion-page>
        ) : (
            <ion-page class="sg-body generic">
            <div className="page-container">
            <MetaHelmet 
                title={t('not-found')}
            />
            <div
                className="logo-container"
                style={{ backgroundImage: `url("/icons/smartGastro-rund-white.svg")` }}
            >
                <h1
                    style={{ width: '300px' }}
                    className="start-title not-found">{t('not-found')}</h1>
            </div>
            {(language === 'de') &&
                <div className="generic-page center german">
                    Tut uns leid! Deine Seite wurde leider nicht gefunden.
                    <br />
                    <br />
                    <Link className="sg-button" to="/">Zur Startseite</Link>
                </div>
            }
            {(language !== 'de') &&
                <div className="generic-page center english">
                    Sorry, your page was not found!
                    <br />
                    <br />
                    <Link className="sg-button" to="/">Go to Homepage</Link>
                </div>
            }
            </div>
        </ion-page>
        )
    );
}

export default pageTransition(NotFound);

