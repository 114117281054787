export function changeSortingPreference(ev: CustomEvent) {
    localStorage.setItem('sorting', ev.detail.value);
    console.log(ev.detail.value)
    return ev.detail.value;
}

export function getVenueOpeningHours(opening_hours: any, time: number, dow: number) {
    let openingHours = opening_hours;
    let returnValue = 0;
    let openValue = 0;

    if (openingHours.length) {
        for (let counter = 0; counter < openingHours.length; counter++) {
            let openingHour = openingHours[counter];

            let dayOpening = parseFloat(openingHour['restaurant__opening_hours-dow_from']);
            let dayClosing = parseFloat(openingHour['restaurant__opening_hours-dow_till']);
            let timeOpening = parseFloat(openingHour['restaurant__opening_hours-open']);
            let timeClosing = parseFloat(openingHour['restaurant__opening_hours-close']);

            if (timeClosing < timeOpening) {
                // Vortagcheck
                returnValue = isVenueOpen(time, dow, dayOpening, dayClosing + 1, 0, timeClosing);
                if (returnValue <= 0) {
                    // bis 24 uhr check
                    returnValue = isVenueOpen(time, dow, dayOpening, dayClosing, timeOpening, timeClosing + 24);
                    if (returnValue > 24) returnValue -= 24;
                }
            } else {
                returnValue = isVenueOpen(time, dow, dayOpening, dayClosing, timeOpening, timeClosing);
            }

            if (returnValue > 0 && returnValue > openValue) {
                openValue = returnValue;
            } //größte schließzeit am tag wird angegeben
            else if (returnValue < 0 && openValue === 0) {
                openValue = returnValue;
            } // erste öffnungszeit am tag wird angegeben
            else if (returnValue < 0 && returnValue > openValue) {
                openValue = returnValue;
            } // niedriegste öffnungszeitn wird angegeben, eg bei küchenzeiten
        }
        // ende schleife
    }
    return openValue;
}


// feedback: 0 = geschlossen, >0 geöffnet schließt um, <0 geschlossen öffnet um
export function isVenueOpen(selectedTime: number, selectedDay: number, dayOpening: number, dayClosing: number, timeOpening: number, timeClosing: number) {

    // Daycheck
    if (dayOpening <= selectedDay && dayClosing >= selectedDay) {

        // Timecheck
        if (timeOpening <= selectedTime && timeClosing >= selectedTime) {
            return timeClosing;
        }
        // wenn das Lokal am selben Tag noch öffnet - mit minus angeben
        else if (timeOpening > selectedTime) {
            return -(timeOpening)
        }
    }
    return 0;
}

export function openValueToTimeString(openValue: number): string {
    // Extract the whole number part as hours
    const hours = Math.floor(openValue);

    // Extract the decimal part as minutes
    const minutes = Math.round((openValue - hours) * 60);

    // Format the result as HH:mm
    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

    return formattedTime;
}

export function checkWarmKitchen(hours: []) {
    let warmcounter = 0;
    let openingcounter = 0;

    for (const openHours of hours) {
        let warmCuisine = (openHours['restaurant__opening_hours-warm_cuisine']);
        if (warmCuisine) {
            warmcounter += 1;
            openingcounter += 1;
        } else {
            openingcounter += 1;
        }
    }

    if (warmcounter === openingcounter || warmcounter === 0) {
        return false;
    } else {
        return true;
    }

}

// true: opening and warm cousine, false: only opening
export function calculateOpenHours(hours: [], writewarm: boolean, language: string | null) {
    let week = Array(8);

    for (let dindex = 1; dindex <= 8; dindex++) {

            let day = {
                'name': getDayOfIndex(dindex, language),
                'hours': "",
                'hours_warm': "",
            }

            week[dindex] = day;
    }

    for (const openHours of hours) {
        let openingHours = openHours['restaurant__opening_hours-open'];
        let closingHours = openHours['restaurant__opening_hours-close'];
        let warmCuisine = (openHours['restaurant__opening_hours-warm_cuisine']);

        if (writewarm) {

            let dayStart = parseInt(openHours['restaurant__opening_hours-dow_from']);
            let dayEnd = parseInt(openHours['restaurant__opening_hours-dow_till']);

            for (let index = dayStart; index <= dayEnd; index++) {

                if(warmCuisine) {
                    let warm_hours = openValueToTimeString(openingHours) + " - " + openValueToTimeString(closingHours);

                    if(week[index].hours_warm.length < 1){
                        week[index].hours_warm += warm_hours;
                    }
                    else{
                        week[index].hours_warm += ' | ' + warm_hours;
                    }  
                }
                else {
                    let hours = openValueToTimeString(openingHours) + " - " + openValueToTimeString(closingHours);

                    if(week[index].hours.length < 1){
                        week[index].hours += hours;
                    }
                    else{
                        week[index].hours += ' | ' + hours;
                    }  
                }
            }
        }
        else {
            let dayStart = parseInt(openHours['restaurant__opening_hours-dow_from']);
            let dayEnd = parseInt(openHours['restaurant__opening_hours-dow_till']);

            for (let index = dayStart; index <= dayEnd; index++) {
                let hours = openValueToTimeString(openingHours) + " - " + openValueToTimeString(closingHours);

                if(week[index].hours.length < 1){
                    week[index].hours += hours;
                }
                else{
                    week[index].hours += ' | ' + hours;
                }  
            }
        }
    }
    return week;
}

export function getOpenDOW(hours: [], language: string | null) {
    let week = Array(8);

    for (let i = 0; i <= 7; i++) {

        let day = {
            'name': getDayOfIndexShort(i + 1, language),
            'state': false
        }
        week[i] = day;
    }

    if(hours) {
        for (const openHours of hours) {
            let dayStart = parseInt(openHours['restaurant__opening_hours-dow_from']);
            let dayEnd = parseInt(openHours['restaurant__opening_hours-dow_till']);
    
            for (let index = dayStart; index <= dayEnd; index++) {
                week[index - 1].state = true;
            }
        }
    }
    
    return week;
}

export function getDayOfIndex(index: number, language: string | null) {
    if (language === 'de') {
        switch (index) {
            case 1:
                return 'Montag';
            case 2:
                return 'Dienstag';
            case 3:
                return 'Mittwoch';
            case 4:
                return 'Donnerstag';
            case 5:
                return 'Freitag';
            case 6:
                return 'Samstag';
            case 7:
                return 'Sonntag';
            case 8:
                return 'Feiertag';
        }
    } else {
        switch (index) {
            case 1:
                return 'Monday';
            case 2:
                return 'Tuesday';
            case 3:
                return 'Wednesday';
            case 4:
                return 'Thursday';
            case 5:
                return 'Friday';
            case 6:
                return 'Saturday';
            case 7:
                return 'Sunday';
            case 8:
                return 'Holiday';
        }
    }
}

function getDayOfIndexShort(index: number, language: string | null) {
    if (language === 'de') {
        switch (index) {
            case 1:
                return 'MO';
            case 2:
                return 'DI';
            case 3:
                return 'MI';
            case 4:
                return 'DO';
            case 5:
                return 'FR';
            case 6:
                return 'SA';
            case 7:
                return 'SO';
            case 8:
                return 'FT';
        }
    } else {
        switch (index) {
            case 1:
                return 'MO';
            case 2:
                return 'TU';
            case 3:
                return 'WE';
            case 4:
                return 'TH';
            case 5:
                return 'FR';
            case 6:
                return 'SA';
            case 7:
                return 'SU';
            case 8:
                return 'HD';
        }
    }
}