// Holiday.tsx

// Feiertage in Österreich für 2024 bis 2030
const holidays: { [key: string]: { name: string; date: string }[] } = {
    "2024": [
      { name: 'Neujahr', date: '1.1.2024' },
      { name: 'Heilige Drei Könige', date: '6.1.2024' },
      { name: 'Ostermontag', date: '1.4.2024' },
      { name: 'Staatsfeiertag', date: '1.5.2024' },
      { name: 'Christi Himmelfahrt', date: '9.5.2024' },
      { name: 'Pfingstmontag', date: '20.5.2024' },
      { name: 'Fronleichnam', date: '30.6.2024' },
      { name: 'Mariä Himmelfahrt', date: '15.8.2024' },
      { name: 'Nationalfeiertag', date: '26.10.2024' },
      { name: 'Allerheiligen', date: '1.11.2024' },
      { name: 'Mariä Empfängnis', date: '8.12.2024' },
      { name: 'Christtag', date: '25.12.2024' },
      { name: 'Stefanitag', date: '26.12.2024' },
    ],
    "2025": [
      { name: 'Neujahr', date: '1.1.2025' },
      { name: 'Heilige Drei Könige', date: '6.1.2025' },
      { name: 'Ostermontag', date: '21.4.2025' },
      { name: 'Staatsfeiertag', date: '1.5.2025' },
      { name: 'Christi Himmelfahrt', date: '29.5.2025' },
      { name: 'Pfingstmontag', date: '9.6.2025' },
      { name: 'Fronleichnam', date: '19.6.2025' },
      { name: 'Mariä Himmelfahrt', date: '15.8.2025' },
      { name: 'Nationalfeiertag', date: '26.10.2025' },
      { name: 'Allerheiligen', date: '1.11.2025' },
      { name: 'Mariä Empfängnis', date: '8.12.2025' },
      { name: 'Christtag', date: '25.12.2025' },
      { name: 'Stefanitag', date: '26.12.2025' },
    ],
    "2026": [
      { name: 'Neujahr', date: '1.1.2026' },
      { name: 'Heilige Drei Könige', date: '6.1.2026' },
      { name: 'Ostermontag', date: '6.4.2026' },
      { name: 'Staatsfeiertag', date: '1.5.2026' },
      { name: 'Christi Himmelfahrt', date: '14.5.2026' },
      { name: 'Pfingstmontag', date: '25.5.2026' },
      { name: 'Fronleichnam', date: '4.6.2026' },
      { name: 'Mariä Himmelfahrt', date: '15.8.2026' },
      { name: 'Nationalfeiertag', date: '26.10.2026' },
      { name: 'Allerheiligen', date: '1.11.2026' },
      { name: 'Mariä Empfängnis', date: '8.12.2026' },
      { name: 'Christtag', date: '25.12.2026' },
      { name: 'Stefanitag', date: '26.12.2026' },
    ],
    "2027": [
      { name: 'Neujahr', date: '1.1.2027' },
      { name: 'Heilige Drei Könige', date: '6.1.2027' },
      { name: 'Ostermontag', date: '29.3.2027' },
      { name: 'Staatsfeiertag', date: '1.5.2027' },
      { name: 'Christi Himmelfahrt', date: '6.5.2027' },
      { name: 'Pfingstmontag', date: '17.5.2027' },
      { name: 'Fronleichnam', date: '27.5.2027' },
      { name: 'Mariä Himmelfahrt', date: '15.8.2027' },
      { name: 'Nationalfeiertag', date: '26.10.2027' },
      { name: 'Allerheiligen', date: '1.11.2027' },
      { name: 'Mariä Empfängnis', date: '8.12.2027' },
      { name: 'Christtag', date: '25.12.2027' },
      { name: 'Stefanitag', date: '26.12.2027' },
    ],
    "2028": [
      { name: 'Neujahr', date: '1.1.2028' },
      { name: 'Heilige Drei Könige', date: '6.1.2028' },
      { name: 'Ostermontag', date: '17.4.2028' },
      { name: 'Staatsfeiertag', date: '1.5.2028' },
      { name: 'Christi Himmelfahrt', date: '25.5.2028' },
      { name: 'Pfingstmontag', date: '5.6.2028' },
      { name: 'Fronleichnam', date: '15.6.2028' },
      { name: 'Mariä Himmelfahrt', date: '15.8.2028' },
      { name: 'Nationalfeiertag', date: '26.10.2028' },
      { name: 'Allerheiligen', date: '1.11.2028' },
      { name: 'Mariä Empfängnis', date: '8.12.2028' },
      { name: 'Christtag', date: '25.12.2028' },
      { name: 'Stefanitag', date: '26.12.2028' },
    ],
    "2029": [
      { name: 'Neujahr', date: '1.1.2029' },
      { name: 'Heilige Drei Könige', date: '6.1.2029' },
      { name: 'Ostermontag', date: '2.4.2029' },
      { name: 'Staatsfeiertag', date: '1.5.2029' },
      { name: 'Christi Himmelfahrt', date: '10.5.2029' },
      { name: 'Pfingstmontag', date: '21.5.2029' },
      { name: 'Fronleichnam', date: '31.5.2029' },
      { name: 'Mariä Himmelfahrt', date: '15.8.2029' },
      { name: 'Nationalfeiertag', date: '26.10.2029' },
      { name: 'Allerheiligen', date: '1.11.2029' },
      { name: 'Mariä Empfängnis', date: '8.12.2029' },
      { name: 'Christtag', date: '25.12.2029' },
      { name: 'Stefanitag', date: '26.12.2029' },
    ],
    "2030": [
      { name: 'Neujahr', date: '1.1.2030' },
      { name: 'Heilige Drei Könige', date: '6.1.2030' },
      { name: 'Ostermontag', date: '22.4.2030' },
      { name: 'Staatsfeiertag', date: '1.5.2030' },
      { name: 'Christi Himmelfahrt', date: '30.5.2030' },
      { name: 'Pfingstmontag', date: '10.6.2030' },
      { name: 'Fronleichnam', date: '20.6.2030' },
      { name: 'Mariä Himmelfahrt', date: '15.8.2030' },
      { name: 'Nationalfeiertag', date: '26.10.2030' },
      { name: 'Allerheiligen', date: '1.11.2030' },
      { name: 'Mariä Empfängnis', date: '8.12.2030' },
      { name: 'Christtag', date: '25.12.2030' },
      { name: 'Stefanitag', date: '26.12.2030' },
    ],
  };
  
  // Hilfsfunktion zum Konvertieren eines Strings im Format "1.1.2025" in ein Date-Objekt
  function parseDate(dateString: string): Date {
    const [day, month, year] = dateString.split('.').map(Number);
    return new Date(year, month - 1, day);
  }
  
  // Funktion, um zu prüfen, ob zwei Daten dasselbe Datum sind (ohne Uhrzeit)
  function isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }
  
  // Funktion, um zu prüfen, ob heute ein Feiertag ist
  export function isHoliday(date: Date = new Date()): { isHoliday: boolean; holidayName?: string } {
    const year = date.getFullYear().toString(); // Konvertiere das Jahr in einen String
    const yearHolidays = holidays[year] || [];
  
    for (const holiday of yearHolidays) {
      const holidayDate = parseDate(holiday.date);
      if (isSameDay(date, holidayDate)) {
        return { isHoliday: true, holidayName: holiday.name };
      }
    }
  
    return { isHoliday: false };
  }
  