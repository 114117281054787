import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import pageTransition from "../components/pageTransition";
import { useMatomo } from '@datapunt/matomo-tracker-react';
import CategoryCard from "../components/categoryCard";
import StartSettings from "../components/startSettings";
import LoadingScreen from "../components/loadingScreen";
import MetaHelmet from '../components/metaHelmet';

interface ParentCategoryProps {
  categoriesLoaded: boolean; // Neue Prop für den categoriesLoaded-Status
}

interface Category {
  id: number;
  parent_id: number;
  slug: string;
  name_de: string;
  description_de: string;
  name_en: string;
  description_en: string;
  search_str: string;
  venue_count: number;
}

const ParentCategory: React.FC<ParentCategoryProps> = ({ categoriesLoaded }) => {
  const { t, i18n } = useTranslation();

  // Use `useLocation` to get parent category
  const location = useLocation();
  const path = location.pathname.substring(1);
  const parentCategorySlug = path.split('/')[0];

  // Load settings
  const [settingsSet] = useState<boolean>(() => !!localStorage.getItem('settings-set'));
  const [language] = useState(localStorage.getItem('i18nextLng') || 'en');

  const [categories, setCategories] = useState<any[]>([]);
  const [category, setCategory] = useState<Category | null>(null);

  const { trackPageView } = useMatomo();


  useEffect(() => {
    if (!categoriesLoaded) return;
    trackPageView({
      href: window.location.href, // URL der aktuellen Seite
    });

    //Get parent ID
    let parentId = 0;
    switch (parentCategorySlug) {
      case 'essen':
        parentId = 83;
        break;
      case 'trinken':
        parentId = 84;
        break;
      case 'aktivitaeten':
        parentId = 85;
        break;
      case 'events':
        parentId = 86;
        break;
    }
    //Get Categories & Filter
    const tempCategories = localStorage.getItem('categories');
    if (tempCategories) {
      const parsedCategories = JSON.parse(tempCategories);

      // Filter categories based on parentId
      const cats = parsedCategories.filter((el: any) => el.parent_id === parentId);
      setCategories(cats.sort((a: { venue_count: number }, b: { venue_count: number }) => b.venue_count - a.venue_count));

      let currentCategoryId = -1;
      parsedCategories.forEach((item: any) => {
        if (item.slug === parentCategorySlug) {
          setCategory(item);
          currentCategoryId = item.id;
        }
      });
    }
  }, [location, categoriesLoaded]);

  return (
    !settingsSet ? (
      <ion-page class="sg-body">
        <StartSettings />
      </ion-page>
    ) : (
      categoriesLoaded && category ? (
        <ion-page class="sg-body">
          <div className="page-container">
          <MetaHelmet 
              title= {language === 'de' ? category.name_de : category.name_en}
              desc= {language === 'de' ? category?.description_de : category?.description_en}
          />
          <ion-header class="ion-padding category-header" mode="ios">
            <div className="top-margin header-container">
              <h1 className="start-title parent left"
                style={{ backgroundImage: `url("/icons/${category.slug}-white.svg")` }}>
                {language === 'de' ? category.name_de : category.name_en}
              </h1>
            </div>
          </ion-header>
          <div className="grid-container">
            {(category.id === 83 || category.id === 84) && (
              <CategoryCard
                key={83}
                currentUrl={parentCategorySlug}
                slug={'alle'}
                name={t('allCategories')}
                isParent={false}
              />
              
            )}
            {(category.id === 83 || category.id === 84) && (
              <CategoryCard
                key={139}
                currentUrl={parentCategorySlug}
                slug={'neu'}
                name={t('new')}
                isParent={false}
              />
              
            )}
            {categories.map(item => (
              language === 'de' ? (
                <CategoryCard
                  key={item.id}
                  currentUrl={parentCategorySlug}
                  slug={item.slug}
                  name={item.name_de}
                  isParent={false}
                />
              ) : (
                <CategoryCard
                  key={item.id}
                  currentUrl={parentCategorySlug}
                  slug={item.slug}
                  name={item.name_en}
                  isParent={false}
                />
              )
            ))}
          </div>
          </div>
        </ion-page>
      ) : (
        <ion-page class="sg-body">
          <LoadingScreen />
        </ion-page>
      )
    )
  );
};

export default pageTransition(ParentCategory);
