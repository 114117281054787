import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { defineCustomElements } from '@ionic/core/loader';
import App from './App';
import "@fontsource/montserrat";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import { HelmetProvider } from 'react-helmet-async';

// Ionic CSS
import '@ionic/core/css/core.css';
import '@ionic/core/css/ionic.bundle.css';

//import "./assets/css/general.css"

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

import './scss/main.scss';


// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);

const container = document.getElementById('root')!;

if (container.hasChildNodes()) {
  hydrateRoot(container,
    <BrowserRouter>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </BrowserRouter>
  );
} else {
  createRoot(container).render(
    <BrowserRouter>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </BrowserRouter>
  );
}