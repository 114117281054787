import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from 'react-router-dom';
import axios from 'axios';
import "./helper/i18n";
import i18n, { t } from "i18next";
import { AnimatePresence } from 'framer-motion';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
// Pages
import Home from './pages/Home';
import ParentCategory from './pages/ParentCategory';
import Category from './pages/Category';
import Venue from './pages/Venue';
import News from './pages/News';
import Settings from './pages/Settings';
import Post from './pages/Post';
import SearchResults from './pages/SearchResults';
import DataPrivacy from './pages/DataPrivacy';
import Impressum from './pages/Impressum';
import AboutUs from "./pages/AboutUs";
import NotFound from "./pages/NotFound";
import WeeklyEvent from "./pages/WeeklyEvent";
import Concert from "./pages/Concert";
import QrCode from "./pages/QrCode";
import Gallery from "./pages/Gallery";
import NewVenues from "./pages/NewVenues";
import OldCat from "./pages/OldCat";

const App: React.FC = () => {
  const [categoriesLoaded, setCategoriesLoaded] = useState(false);
  const [venuesLoaded, setVenuesLoaded] = useState(false);
  const [newsLoaded, setNewsLoaded] = useState(false);
  const [adsLoaded, setAdsLoaded] = useState(false);
  const [eventsLoaded, setEventsLoaded] = useState(false);

  // Data Loading Functions
  const getCategoriesFromApi = async () => {
      try {
        const query = await axios
          .get("https://graz.smartgastro.at/wp-json/capi/v1/categories")
          .then(res => {
            localStorage.setItem('categories', JSON.stringify(res.data));
          }
          )
        setCategoriesLoaded(true);
      } catch (e) {
        console.log(e);

      }
  }

  const getVenuesFromApi = async () => {
      try {
        const query = await axios
          .get("https://graz.smartgastro.at/wp-json/capi/v1/venues")
          .then(res => {
            localStorage.setItem('venues', JSON.stringify(res.data));
          }
          )
        setVenuesLoaded(true);
      } catch (e) {
        console.log(e);
      }
  }

  const getEventsFromApi = async () => {
      try {
        const query = await axios
          .get("https://graz.smartgastro.at/wp-json/capi/v1/events")
          .then(res => {
            localStorage.setItem('events', JSON.stringify(res.data));
          }
          )
        setEventsLoaded(true);
      } catch (e) {
        console.log(e);
      }
  }

  const getNewsFromApi = async () => {
      try {
        const query = await axios
          .get("https://graz.smartgastro.at/wp-json/capi/v1/posts")
          .then(res => {
            localStorage.setItem('news', JSON.stringify(res.data));
          }
          )
        setNewsLoaded(true);
      } catch (e) {
        console.log(e);
      }
  }

  const getAdsFromApi = async () => {
    try {
      const query = await axios
        .get("https://graz.smartgastro.at/wp-json/capi/v1/ads")
        .then(res => {
          localStorage.setItem('ads', JSON.stringify(res.data));
        }
        )
      setAdsLoaded(true);
    } catch (e) {
      console.log(e);
    }
}

  const setLocalStorageTimeStamp = () => {
    const currentDate = new Date();
    const formattedDate = parseInt(`${currentDate.getFullYear()}${(currentDate.getMonth() + 1).toString().padStart(2, '0')}${currentDate.getDate().toString().padStart(2, '0')}`);
    localStorage.setItem('last-update', JSON.stringify(formattedDate));
  }
  
  const lastUpdateIsOld = () => {
    const lastUpdateString = localStorage.getItem('last-update');
    const lastUpdate = lastUpdateString ? JSON.parse(lastUpdateString) : null;
    if (!lastUpdate) {
      console.log('No last update date, API update will be performed.');
      return true;
    }
    const currentDate = new Date();
    const formCurrentDate = parseInt(`${currentDate.getFullYear()}${(currentDate.getMonth() + 1).toString().padStart(2, '0')}${currentDate.getDate().toString().padStart(2, '0')}`);
  
    if(lastUpdate < formCurrentDate){
      console.log('Last update was on ' + lastUpdate +', API update will be performed.');
      return true;
    }
    else{
      return false;
    }
  }

  //matomo instance

    // Matomo instance
    const matomoInstance = createInstance({
      urlBase: 'https://matomo.smartgastro.at/', // Ersetze durch deine Matomo-URL
      siteId: 1, // Ersetze durch deine Site-ID
      trackerUrl: 'https://matomo.smartgastro.at/matomo.php',
      srcUrl: 'https://matomo.smartgastro.at/matomo.js',
      configurations: {
        disableCookies: true, // Deaktiviert das Setzen von Cookies
      },
    });
  

  useEffect(() => {
    if (typeof window === 'undefined') {
      // Skip data loading on the server
      return;
    }
    
    console.log("Start React App");

    if (!localStorage.getItem('categories') || !localStorage.getItem('venues')
      || !localStorage.getItem('events') || !localStorage.getItem('news') || lastUpdateIsOld()) {
      getCategoriesFromApi();
      getVenuesFromApi();
      setLocalStorageTimeStamp();
    } else {
      setCategoriesLoaded(true);
      setVenuesLoaded(true);
    }
    getEventsFromApi();
    getNewsFromApi();
    getAdsFromApi();
  }, []);

  // Server-side rendering logic: simple ion-page structure
  if (typeof window === 'undefined') {
    return (
      <ion-app>
        <ion-page>
          <p>Content will be rendered on client side</p>
        </ion-page>
      </ion-app>
    );
  }
  
  // Client-side rendering logic
  return (
    // @ts-ignore
    <MatomoProvider value={matomoInstance}>
    <ion-app>
      <AnimatePresence>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/einstellungen" element={<Settings />}/>
          <Route path="/suche" element={<SearchResults categoriesLoaded={categoriesLoaded} venuesLoaded={venuesLoaded}/>} />
          <Route path="/datenschutz" element={<DataPrivacy />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/qr" element={<QrCode />} />
          {/* Posts */}
          <Route path="/aktuelles" element={<News newsLoaded={newsLoaded}/>} />
          <Route path="/aktuelles/:post" element={<Post newsLoaded={newsLoaded}/>} />
          {/* Parent Categories */}
          <Route path="/essen" element={<ParentCategory categoriesLoaded={categoriesLoaded} />} />
          <Route path="/trinken" element={<ParentCategory categoriesLoaded={categoriesLoaded} />} />
          <Route path="/aktivitaeten" element={<ParentCategory categoriesLoaded={categoriesLoaded} />} />
          <Route path="/events" element={<ParentCategory categoriesLoaded={categoriesLoaded} />} />
          {/* Categories */}
          <Route path="/essen/neu" element={<NewVenues categoriesLoaded={categoriesLoaded} venuesLoaded={venuesLoaded} />} />
          <Route path="/trinken/neu" element={<NewVenues categoriesLoaded={categoriesLoaded} venuesLoaded={venuesLoaded} />} />
          {/* Categories */}
          <Route path="/essen/:category" element={<Category categoriesLoaded={categoriesLoaded} venuesLoaded={venuesLoaded}  />} />
          <Route path="/trinken/:category" element={<Category categoriesLoaded={categoriesLoaded} venuesLoaded={venuesLoaded}  />} />
          <Route path="/aktivitaeten/:category" element={<Category categoriesLoaded={categoriesLoaded} venuesLoaded={venuesLoaded}  />} />
          {/* Venues */}
          <Route path="/betriebe/:venue" element={<Venue categoriesLoaded={categoriesLoaded} venuesLoaded={venuesLoaded} />} />
          <Route path="/betriebe/:venue/galerie" element={<Gallery venuesLoaded={venuesLoaded} />} />
          {/* Events */}
          <Route path="/events/pubquiz" element={<WeeklyEvent categoriesLoaded={categoriesLoaded} eventsLoaded={eventsLoaded} />} />
          <Route path="/events/happy-hour" element={<WeeklyEvent categoriesLoaded={categoriesLoaded} eventsLoaded={eventsLoaded} />} />
          <Route path="/events/karaoke" element={<WeeklyEvent categoriesLoaded={categoriesLoaded} eventsLoaded={eventsLoaded} />} />
          <Route path="/events/live-musik" element={<Concert categoriesLoaded={categoriesLoaded} eventsLoaded={eventsLoaded} />} />
          <Route path="/events/kino" element={<Category categoriesLoaded={categoriesLoaded} venuesLoaded={venuesLoaded}  />} />
          <Route path="/events/club-disco" element={<Category categoriesLoaded={categoriesLoaded} venuesLoaded={venuesLoaded}  />} />
          {/* Redirect für alte Category URL */}
          <Route path="/betriebe" element={<OldCat />} />
          {/* Not Found */}
          <Route path="/not-found" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/not-found" replace />} />
        </Routes> 
      </AnimatePresence>
      <ion-footer class="footer" translucent>
        <div className="footer-inner">
          <Link to="/aktuelles" style={{ backgroundImage: `url("/icons/news-green.svg")` }}>
            <span className="footer-link-name">{t('news')}</span>
          </Link>
          <Link to="/" style={{ backgroundImage: `url("/icons/home-green.svg")` }}>
            <span className="footer-link-name">{t('home')}</span>
          </Link>
          <Link to="/einstellungen" style={{ backgroundImage: `url("/icons/settings-green.svg")` }}>
            <span className="footer-link-name">{t('settings')}</span>
          </Link>
        </div>
      </ion-footer>
    </ion-app>
    </MatomoProvider>
  );

};

export default App;
