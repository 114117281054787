import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function SettingsPanel() {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(localStorage.getItem('i18nextLng') || 'en');
    const [sorting, setSorting] = useState(localStorage.getItem('sorting') || 'popularity');
    const [statistics, setStatistics] = useState('detail');
    const [theme, setTheme] = useState('light');

    const changeLanguage = (lang: string) => {
        console.log("Selected language:", lang);
        i18n.changeLanguage(lang);
        setLanguage(lang);
    };

    const changeSortingPreference = (sorting: string) => {
        console.log("Sorting set to:", sorting);
        localStorage.setItem('sorting', sorting);
        setSorting(sorting);
    };

    const changeStatistics = (stats: string) => {
        console.log("Statistics set to:", stats);
        localStorage.setItem('statistics', stats);
        setStatistics(stats);
    };
  
    const changeColorTheme = (ev: CustomEvent) => {
        localStorage.setItem('color-scheme', ev.detail.value);
        document.body.setAttribute('data-scheme', ev.detail.value);
        console.log(ev.detail.value);
        setTheme(ev.detail.value);
    };

    useEffect(() => {
        const segmentLanguage = document.getElementById('segmentLanguage');
        const segmentSorting = document.getElementById('segmentSorting');

        const handleLanguageChange = (event: Event) => {
            const customEvent = event as CustomEvent;
            const selectedLanguage = customEvent.detail.value;
            changeLanguage(selectedLanguage);
        };

        const handleSortingChange = (event: Event) => {
            const customEvent = event as CustomEvent;
            const selectedSorting = customEvent.detail.value;
            changeSortingPreference(selectedSorting);
        };

        // Add event listeners
        segmentLanguage?.addEventListener('ionChange', handleLanguageChange as EventListener);
        segmentSorting?.addEventListener('ionChange', handleSortingChange as EventListener);

        // Cleanup event listeners on component unmount
        return () => {
            segmentLanguage?.removeEventListener('ionChange', handleLanguageChange as EventListener);
            segmentSorting?.removeEventListener('ionChange', handleSortingChange as EventListener);
        };
    }, []);

    return (
        <div className="settings-panel">
            <ion-list-header class="settings-heading">{t('language')}</ion-list-header>
            <ion-list mode="ios" class="preference-item" inset={true}>
                <ion-segment class="segment" id="segmentLanguage" value={language}>
                    <ion-segment-button class="segment-button" value="de">
                        <label>{t('german')}</label>
                    </ion-segment-button>
                    <ion-segment-button class="segment-button" value="en">
                        <label>{t('english')}</label>
                    </ion-segment-button>
                </ion-segment>
            </ion-list>
            <ion-list-header class="settings-heading">{t('sorting_order')}</ion-list-header>
            <ion-list mode="ios" class="preference-item" inset={true}>
                <ion-segment class="segment" id="segmentSorting" value={sorting}>
                    <ion-segment-button class="segment-button" value="popularity">
                        <label>{t('popularity')}</label>
                    </ion-segment-button>
                    <ion-segment-button class="segment-button" value="distance">
                        <label>{t('distance')}</label>
                    </ion-segment-button>
                </ion-segment>
            </ion-list>
            <div className="data-privacy consent-info">
                <Link to="/about-us">{t('about-us')}</Link>
                <Link to="/datenschutz">{t('data-privacy')}</Link>
                <Link to="/impressum">Impressum</Link>
            </div>
        </div>
    );
}
