import React, { useEffect } from "react";
import { t } from "i18next";
import SettingsPanel from "./settingsPanel";

export default function StartSettings() {




    const savePreferences = () => {
        const now = new Date();
        const formattedDate = now.toLocaleString('de-DE', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        });
    
        localStorage.setItem('settings-set', 'yes');
        localStorage.setItem('consent-given', formattedDate);
    
        // Reload the app
        window.location.reload();
    };
    

    useEffect(() => {
    }, []);

    return (
            <div className="settings outerContainer">
                <div className="settings innerContainer">
                    <div
                        className="logo-container settings"
                        style={{ backgroundImage: `url("/icons/smartGastro-rund-white.svg")` }}
                    >
                        <h1
                            style={{ width: '300px' }}
                            className="start-title">{t('welcomeText')}</h1>
                    </div>
                    <SettingsPanel />
                    <div className="consent-info">{t('consentInfo')}</div>
                    <button className="sg-button" onClick={savePreferences}>
                        {t('consent-ok')}
                    </button>
                </div>
            </div>
    )
}