import React, { useEffect, useState } from "react";
import { t } from "i18next";

export default function LoadingScreen() {
    const [wait, setWait] = useState(true);

    useEffect(() => {
        // Setze einen Timer für 15 Sekunden (15000 Millisekunden)
        const timer = setTimeout(() => {
            setWait(false);
        }, 10000);
        return () => clearTimeout(timer);
    }, []);

    const handleReload = () => {
        localStorage.clear();
        window.location.reload();
    };

    return (
        <div className="center-screen">
                <div className="loading-container">
                    <div className="logo-spinner-container">
                        <ion-spinner name="crescent" class="spinner" />
                        <div className="logo-container1" style={{ backgroundImage: `url("/icons/smartGastro-rund-white.svg")` }}></div>
                    </div>
                    {!wait && (
                    <>
                    <div className="loading-info">{t('loadingInfo')}</div>
                    <button className="sg-button" onClick={handleReload}>
                        {t('reload')}
                    </button>
                    </>
                    )}
                </div>
        </div>
    )
}
