import React, { useEffect, useState } from "react";
import PostCard from "../components/postCard";
import { useLocation } from 'react-router-dom';
import pageTransition from "../components/pageTransition";
import StartSettings from "../components/startSettings";
import { useTranslation } from "react-i18next";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MetaHelmet from "../components/metaHelmet";
import LoadingScreen from "../components/loadingScreen";

interface NewsProps {
    newsLoaded: boolean;
}


const News: React.FC<NewsProps> = ({ newsLoaded }) => {
    const location = useLocation();
    const { t } = useTranslation();

    const { trackPageView } = useMatomo();
    // Load settings
    const [settingsSet] = useState<boolean>(() => !!localStorage.getItem('settings-set'));

    const [posts, setPosts] = useState<any[]>([]);

    useEffect(() => {
        if (!newsLoaded) return;
        trackPageView({
            href: window.location.href, // URL der aktuellen Seite
          });
        let tempPosts = localStorage.getItem('news');
        // @ts-ignore
        setPosts(JSON.parse(tempPosts));
    }, [location, newsLoaded]);

    return (
        !settingsSet ? (
            <ion-page class="sg-body">
                <StartSettings />
            </ion-page>
        ) : (
            newsLoaded ? (
                <ion-page class="sg-body">
                    <div className="page-container">
                    <MetaHelmet 
                        title= {t('news')}
                        desc= "Entdecke die aktuellsten Neuigkeiten zur Gastronomieszene in Graz auf smartGastro: Neue Restaurant- und Bar-Eröffnungen, spannende Interviews und Berichte über einzigartige Küchen der Stadt. Immer informiert über die kulinarischen Trends in Graz!"
                    />
                    <ion-header class="ion-padding" mode="ios">
                        <h1
                            className="start-title left padding news">{t('news')}
                        </h1>
                    </ion-header>
                    <div className="grid-container news">
                        {posts.map(item => {
                            return <PostCard key={item['id']} post={item} />;
                        })}
                    </div>
                    </div>
                </ion-page>
            ) : (
                <ion-page class="sg-body">
                    <LoadingScreen />
              </ion-page>
            )
        )
    )
}

export default pageTransition(News);