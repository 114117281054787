import React, { useRef, useEffect, useState } from 'react';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';

interface MapComponentProps {
  markers: MarkerImport[];
  onMarkerSelected: (selectedMarkerID: number | null) => void;
}

type MarkerImport = {
  id: number;
  title?: string;
  isOpen: boolean;
  restaurant__gps_lat: number;
  restaurant__gps_lon: number;
};

const CategoryMap: React.FC<MapComponentProps> = ({ markers, onMarkerSelected }) => {

  const mapContainer = useRef(null);
  const map = useRef<maplibregl.Map | null>(null);
  const graz = { lng: 15.4395, lat: 47.0707 };
  const [zoom] = useState(12);

  const categoryMarkers = useRef<MarkerImport[]>(markers);
  const markersOnMap = useRef<maplibregl.Marker[]>([]);
  const oldSelectedMarkerIndex = useRef(-1);
  const selectedMarkerIndex = useRef(-1);
  const markerSelectionRef = useRef(false);

  const handleMapClick = (e: any) => {
    if (!markerSelectionRef.current) {
      selectedMarkerIndex.current = -1;
    }

    if (selectedMarkerIndex !== oldSelectedMarkerIndex) {
      resetMarkers();
      oldSelectedMarkerIndex.current = selectedMarkerIndex.current;

      if (selectedMarkerIndex.current >= 0) {
        onMarkerSelected(categoryMarkers.current[selectedMarkerIndex.current].id);
      } else {
        onMarkerSelected(-1);
      }
    }
    markerSelectionRef.current = false;
  };

  useEffect(() => {
    if (map.current) return; // stops map from initializing more than once

    map.current = new maplibregl.Map({
      container: mapContainer.current!,
      style: `https://api.maptiler.com/maps/basic/style.json?key=KHWONvKvBhvZQOL37lv8`,
      center: [graz.lng, graz.lat],
      zoom: zoom,
      attributionControl: false,
    });



    

    map.current.on('click', handleMapClick);

    setTimeout(() => {
      map.current?.resize();

    // Hinzufügen des GeolocateControl Buttons
    const geolocateControl = new maplibregl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: false,
      showAccuracyCircle: true
    });

      map.current?.addControl(geolocateControl, 'bottom-right');
    }, 500);

    addMarkers();

  }, [graz.lng, graz.lat, zoom, markers, handleMapClick]);

  useEffect(() => {
    categoryMarkers.current = markers;
    selectedMarkerIndex.current = -1;
    resetMarkers();
  }, [markers]);

  const resetMarkers = () => {
    if (map.current) {
      markersOnMap.current.forEach((marker) => marker.remove());
      markersOnMap.current = [];
      addMarkers();
    }
  };

  const addMarkers = () => {
    if (!map.current) return;

    const newMarkers: maplibregl.Marker[] = [];

    for (let i = 0; i < categoryMarkers.current.length; i++) {
      const color = selectedMarkerIndex.current === i ? '#F1F1F1' : categoryMarkers.current[i].isOpen ? '#698047' : '#808080';

      const newMarker = new maplibregl.Marker({
        color,
        draggable: false,
      })
        .setLngLat([categoryMarkers.current[i].restaurant__gps_lon, categoryMarkers.current[i].restaurant__gps_lat])
        .addTo(map.current!);

      newMarker.getElement()?.addEventListener('click', markerClick(i));

      if (selectedMarkerIndex.current === i) {
        newMarker.getElement()?.classList.add('selected');
      }
      newMarkers.push(newMarker);
    }
    markersOnMap.current = newMarkers;
  };

  const markerClick = React.useCallback(
    (index: number) => () => {
      selectedMarkerIndex.current = index;
      markerSelectionRef.current = true;
    },
    []
  );

  return (
    <>
      <div className="map-wrap">
        <div ref={mapContainer} className="map" />
      </div>
    </>
  );
}

export default CategoryMap;
