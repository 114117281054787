import React, { useEffect, useState } from "react";
import pageTransition from "../components/pageTransition";
import { useLocation } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MetaHelmet from "../components/metaHelmet";

const Impressum: React.FC = () => {

    // Load settings
    const [language] = useState(localStorage.getItem('i18nextLng') || 'en');

    const location = useLocation();
    const { trackPageView } = useMatomo();
  
    useEffect(() => {
      trackPageView({
        href: window.location.href, // URL der aktuellen Seite
      });
    }, [location]);

    return (
        <ion-page class="sg-body generic">
            <div className="page-container">
            <MetaHelmet 
                title="Impressum"
            />
            <img className="post-image" src={'/images/sG_fallback_small.jpeg'} />
            <div className="header-container ion-padding news">
                <h1 className="start-title">
                    Impressum
                </h1>
            </div>
            {(language === 'de') &&
                <div className="generic-page german">
                    Niklas Pucher<br />
                    Mariagrünerstraße 33/9<br />
                    8043 Graz, Österreich
                    <br />
                    <br />
                    <b>Kontaktdaten:</b><br />
                    <br />
                    Tel.: <a href="tel:+436764647177">+436764647177</a><br />
                    E-Mail: <a href="mailto:info@smartgastro.at">info@smartgastro.at</a><br />
                    <br />
                    Sollten Sie Fragen zum Datenschutz haben, finden unter folgendem Link unsere <a href="/datenschutz">Datenschutzerklärung</a>
                </div>
            }
            {(language !== 'de') &&
                <div className="generic-page english">
                    Niklas Pucher<br />
                    Mariagrünerstraße 33/9<br />
                    8043 Graz, Austria
                    <br />
                    <br />
                    <b>Contact Information:</b><br />
                    <br />
                    Tel.: <a href="tel:+436764647177">+436764647177</a><br />
                    Email: <a href="mailto:info@smartgastro.at">info@smartgastro.at</a><br />
                    <br />
                    If you have any questions about data protection, you can find our <a href="/datenschutz">privacy policy</a> at the following link.
                </div>
            }
            </div>
        </ion-page>
    );
}

export default pageTransition(Impressum);
