import React, { useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import pageTransition from "../components/pageTransition";
import { useMatomo } from "@datapunt/matomo-tracker-react";



const QrCode: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Extract search query from the URL
    const searchParams = new URLSearchParams(location.search);
    const searchQuery = searchParams.get('id') || '';
    const navigateURL = searchParams.get('goto') || '';

    const { trackPageView } = useMatomo();

    useEffect(() => {
        trackPageView({
        href: window.location.href, // URL der aktuellen Seite
        });
        console.log("-- QR Code Trigger: " + searchQuery + " --");
        console.log("  -> go to " + navigateURL);
        navigate('/'+ navigateURL);
    }, [location]);


    return (
        <ion-page class="sg-body">
        </ion-page>
    )
}

export default pageTransition(QrCode);