import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Detect if the code is running in the browser
const isBrowser = typeof window !== 'undefined';

// Custom language detector to normalize the detected language
const customLanguageDetector = {
  name: 'customLangDetector',

  lookup() {
    if (isBrowser) {
      // On the client side, use navigator.language
      const lang = navigator.language;
      console.log('Browser language: ' + lang );
      const splitLang = lang.split('-')[0]; // Return only base language
      if (splitLang === 'de') {
        return 'de';
      }
      else {
        return 'en';
      }
    } else {
      // SSR
      return 'en'; // Default language for SSR
    }
  },

  cacheUserLanguage(lng: string) {
    if (isBrowser) {
      localStorage.setItem('i18nextLng', lng);
    }
  }
};

const resources = {
        en: {
            translation: {
                "start_title": "What are you up for?",
                "news": "News",
                "essen": "Food",
                "trinken": "Drinks",
                "aktivitaeten": "Activities",
                "events": "Events",
                "settings": "Settings",
                "look_language": "Look & Language",
                "sorting_order": "Sorting of Venues",
                "distance": "Distance",
                "light_theme": "Light Theme",
                "dark_theme": "Dark Theme",
                "german": "German",
                "english": "English",
                "search": "Search",
                "address": "Current position",
                "data-privacy": "Data Privacy",
                "language": "Language",
                "welcomeText": "Welcome to smartGastro!",
                "consentInfo": "We only use technically necessary data. By providing your consent, you confirm the privacy policy of smartGastro.",
                "cookiesInfo": "Detailed information you can find at  ",
                "consent-ok": "ACCEPT & CONTINUE",
                "sortedBy": "Sorted by: ",
                "popularity": "Popularity",
                "categories": "Categories",
                "todayUntil": "Open until ",
                "closed": "Closed",
                "close": "Close",
                "opensAt": ", opens at ",
                "openingHours": "Opening Hours",
                "menu": "Menu",
                "gallery": "Gallery",
                "searchResults": "Search Results",
                "venues": "Venues",
                "home": "Home",
                "warm_kitchen": "Kitchen Hours",
                "statistics": "Statistics",
                "essential": "Essential",
                "essential-stats": "Detail",
                "map": "Map",
                "list": "List",
                "description": "Description",
                "chooseTime": "Date and time",
                "numberPeople": "Number of people",
                "book": "Send booking request",
                "allCategories": "All Categories",
                "about-us": "About Us",
                "not-found": "Not Found",
                "activate": "Activate",
                "disable": "Disable",
                "reset": "Clear",
                "gastgarten": "Outdoor Area",
                "mittagsmenu": "Lunch Menu",
                "parking": "Parking",
                "schnell": "Fastfood",
                "vegetarian": "Vegetarian",
                "vegan": "Vegan",
                "glutenfree": "Glutenfree",
                "playground": "Playground",
                "cancel": "Cancel",
                "done": "Done",
                "reservation": "Reservation",
                "lastApiUpdate": "Last API Update: ",
                "loadingInfo": "It seems there was an issue loading the data. Try reloading the page.",
                "reload": "Reload",
                "confirm": "Confirm",
                "placeholderName": "1st & 2nd Name",
                "clearData": "Clear website data",
                "local-infos": " (Info on site)",
                "newest": "Newest",
                "new": "New Gastro",
                "no-online-menu": "Unfortunately, this venue doesn't have an online link to the current lunch menu. You can find the latest menu information on-site.",
                "request-booking-table": "Create booking request",
                "no_regular_hours": "no regular opening hours",
                "holiday_info": "On holidays, we only mark places as open if they have explicitly indicated that they are open on those days.",
              }
        },
        de: {
            translation: {
                "start_title": "Worauf hast du Lust?",
                "news": "Aktuelles",
                "essen": "Essen",
                "trinken": "Trinken",
                "aktivitaeten": "Aktivitäten",
                "events": "Events",
                "settings": "Einstellungen",
                "look_language": "Look & Sprache",
                "sorting_order": "Sortierung",
                "distance": "Distanz",
                "light_theme": "Helles Farbschema",
                "dark_theme": "Dunkles Farbschema",
                "german": "Deutsch",
                "english": "Englisch",
                "search": "Suche",
                "address": "Aktueller Standort",
                "data-privacy": "Datenschutz",
                "language": "Sprache",
                "welcomeText": "Willkommen auf smartGastro!",
                "consentInfo": "Wir verwenden nur technisch notwendige Daten. Mit deiner Zustimmung bestätigst du die Datenschutzbestimmungen von smartGastro.",
                "cookiesInfo": "Weitere Informationen findest du unter ",
                "consent-ok": "ZUSTIMMEN & FORTFAHREN",
                "sortedBy": "Sortiert nach: ",
                "popularity": "Popularität",
                "categories": "Kategorien",
                "todayUntil": "Geöffnet bis ",
                "closed": "Geschlossen",
                "close": "Schließen",
                "opensAt": ", öffnet um ",
                "openingHours": "Öffnungszeiten",
                "menu": "Speisekarte",
                "gallery": "Galerie",
                "searchResults": "Suchergebnisse",
                "venues": "Betriebe",
                "home": "Start",
                "warm_kitchen": "Warme Küche",
                "statistics": "Statistikdaten",
                "essential": "Essenziell",
                "essential-stats": "Detail",
                "map": "Karte",
                "list": "Liste",
                "description": "Beschreibung",
                "chooseTime": "Datum und Uhrzeit",
                "numberPeople": "Personenanzahl",
                "book": "Reservierungsanfrage senden",
                "allCategories": "Alle Kategorien",
                "about-us": "Über Uns",
                "not-found": "Nicht Gefunden",
                "activate": "Aktivieren",
                "disable": "Zurücksetzen",
                "reset": "Löschen",
                "gastgarten": "Gastgarten",
                "mittagsmenu": "Mittagsmenü",
                "parking": "Parkplatz",
                "schnell": "Schnelles Essen",
                "vegetarian": "Vegetarisch",
                "vegan": "Vegan",
                "glutenfree": "Glutenfrei",
                "playground": "Spielplatz",
                "cancel": "Abbrechen",
                "done": "Fertig",
                "reservation": "Reservierung",
                "lastApiUpdate": "Letztes API Update: ",
                "loadingInfo": "Es scheint ein Problem beim Laden der Daten zu geben. Versuche, die Seite neu zu laden.",
                "reload": "Neuladen",
                "confirm": "Bestätigen",
                "placeholderName": "Vorname Nachname",
                "clearData": "Websitedaten löschen",
                "local-infos": " (Infos vor Ort)",
                "newest": "Neueste",
                "new": "Neue Gastro",
                "no-online-menu": "Leider hat dieses Lokal keinen Online-Link zum aktuellen Mittagsmenü. Aktuelle Informationen zum Menü findest du direkt vor Ort.",
                "request-booking-table": "Reservierungsanfrage erstellen",
                "no_regular_hours": "keine geregelten Öffnungszeiten",
                "holiday_info": "An Feiertagen markieren wir nur Lokale als geöffnet, die explizit angegeben haben, dass sie an diesen Tagen offen sind.",
            }
        },
    }
;

// Register the custom language detector with i18next's language detection service
const languageDetector = new LanguageDetector();
languageDetector.addDetector(customLanguageDetector);

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources, // Add the translation resources
    fallbackLng: 'en', // Default language if none detected
    detection: {
      order: ['localStorage', 'customLangDetector'], // Use the custom detector and localStorage
      caches: ['localStorage'], // Cache detected language in localStorage
    },
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
  });

export default i18n;