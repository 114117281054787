
export function filterVenues(allVenues: any[], categories: number[]) {
    let venues = allVenues.filter(function (el: any) {
        if (categories.every(val => el.category.includes(val) || el.parent_category.includes(val)))  {
            return true;
        }
        return false;
    });

    return venues;
}

export function filterEvents(allEvents: any[], categories: number[]) {
    console.log("filter events");
    let events = allEvents.filter(function (el: any) {
        if (categories.every(val => el.category.includes(val))) {
            return true;
        }
        return false;
    });

    console.log(events);

    return events;
}

export function filterNewest(venues: any[]): any[] {
    // Get the current date
    const currentDate = new Date();
    
    // Calculate the date 3 months ago
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(currentDate.getMonth() - 3);

    // Filter venues with a publish_date within the last 3 months
    return venues.filter(venue => {
        const publishDate = new Date(venue.publish_date);
        return publishDate >= threeMonthsAgo;
    });
}


export function shareUrl() {
    if (navigator.share) {
        navigator.share({
            url: document.URL,
            title: document.title
        })
        .then(() => console.log('Share successful'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
        console.warn('Web Share API is not supported in this browser.');
        navigator.clipboard.writeText(window.location.href);
        const language = localStorage.getItem('i18nextLng') || 'de';
        if(language.includes('en')) {
            showNotification('Copied link to clipboard');
        } else {
            showNotification('Link in die Zwischenablage kopiert');
        }
    }
}

function showNotification(message: string) {
    const notification = document.createElement('span');
    notification.textContent = message;
    notification.style.position = 'fixed';
    notification.style.top = '40px';
    notification.style.padding = '10px 20px';
    notification.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
    notification.style.color = 'white';
    notification.style.borderRadius = '5px';
    notification.style.zIndex = '1000';
    notification.style.transition = 'opacity 0.5s';
    notification.style.width = '300px';
    notification.style.left = 'calc(50% - 150px)';
    notification.style.textAlign = 'center';

    document.body.appendChild(notification);
    setTimeout(() => {
        notification.style.opacity = '0';
        setTimeout(() => {
            document.body.removeChild(notification); 
        }, 500); 
    }, 3000); 
}

export function getCategoryBySlug(slug: any) {
    let allCategories = localStorage.getItem('categories')
    if (allCategories && allCategories.length) {
        allCategories = JSON.parse(allCategories);

        let categories = [];

        // @ts-ignore
        for (let i = 0; i < allCategories.length; i++) {
            // @ts-ignore
            if (slug === allCategories[i].slug) {
                let cat = {
                    // @ts-ignore
                    'slug': allCategories[i].slug,
                    // @ts-ignore
                    'name_de': allCategories[i].name_de,
                    // @ts-ignore
                    'name_en': allCategories[i].name_en,
                    // @ts-ignore
                    'parent_id': allCategories[i].parent_id
                };
                return cat;
            }
        }
    }

    return null;
}

export function getParentSlugOfId(parentID: number) {
    let parentSlug = "";
    switch (parentID) {
        case 83:
            parentSlug = 'essen';
            break;
        case 84:
            parentSlug = 'trinken';
            break;
        case 85:
            parentSlug = 'aktivitaeten';
            break;
        case 86:
            parentSlug = 'events';
            break;
    }

    return parentSlug;
}

////// --- Appearance Helper Functions ----- ///////

export function formatDateToTimeString(date: Date): string {
    // Get hours and minutes from the Date object
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Format the time string with leading zeros if needed
    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

    // Combine hours and minutes into the "HH:mm" format
    const timeString = `${formattedHours}:${formattedMinutes}`;

    return timeString;
}

export function formatDateToDateString(date: Date): string {
    // Get day, month, and year from the Date object
    const day = date.getDate();
    // Note: getMonth() returns zero-based month (0 for January, 1 for February, etc.)
    const month = date.getMonth() + 1; // Adding 1 to get the correct month
    const year = date.getFullYear();

    // Format the date string with leading zeros if needed
    const formattedDay = day < 10 ? `0${day}` : `${day}`;
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;

    // Combine day, month, and year into the "DD.MM.YYYY" format
    const dateString = `${formattedDay}.${formattedMonth}.${year}`;

    return dateString;
}

export function getDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const theta = lon1 - lon2;
    let dist = Math.sin(deg2rad(lat1)) * Math.sin(deg2rad(lat2)) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.cos(deg2rad(theta));
    dist = Math.acos(dist);

    const meters = dist * 6378137; // earth radius in meters at the latitude of Graz
    return Math.round(meters);

    function deg2rad(degrees: number): number {
        return degrees * (Math.PI / 180);
    }
}

export function getVenueById(id: number, venues: any) {
    const foundVenue = venues.find((venue: { id: number; }) => venue.id === id);
    console.log(foundVenue);
    return foundVenue || null;
}

