// src/components/PageTransition.tsx

import React from 'react';
import { motion } from 'framer-motion';

const transitionRightToLeft = {
    initial: {
      opacity: 0,
      x: "100vw", // Startet von rechts
    },
    in: {
      opacity: 1,
      x: 0, // Endet in der normalen Position
    },
    out: {
      opacity: 0,
      x: "-100vw", // Bewegt sich nach links beim Verlassen
    },
  };

  const transitionBottomUp = {
    initial: {
      opacity: 0,
      y: "5%", // Startet von unten
    },
    in: {
      opacity: 1,
      y: 0, // Endet in der normalen Position
    },
    out: {
      opacity: 0,
      //y: "100%", // Bewegt sich nach unten beim Verlassen
    },
  };
  
  const transitionSettings = {
    type: "spring",
    stiffness: 200,
    damping: 30,
    duration: 0.8
};

const pageTransition = <P extends object>(Component: React.ComponentType<P>) => {
  const WrappedComponent: React.FC<P> = (props) => {
    return (
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={transitionBottomUp}
        transition={transitionSettings}
      >
        <Component {...props} />
      </motion.div>
    );
  };

  return WrappedComponent;
};

export default pageTransition;
