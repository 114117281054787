import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SettingsPanel from "../components/settingsPanel";
import pageTransition from "../components/pageTransition";
import StartSettings from "../components/startSettings";
import { useLocation } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MetaHelmet from "../components/metaHelmet";
import Cookies from "js-cookie";



const Settings: React.FC = () => {
    // Load settings
    const [settingsSet] = useState<boolean>(() => !!localStorage.getItem('settings-set'));
    const { t } = useTranslation();

    const location = useLocation();
    const { trackPageView } = useMatomo();

    const [lastUpdate, setLastUpdate] = useState("");

    const parseDate = (timestamp: string) => {
        const year = parseInt(timestamp.substring(0, 4));
        const month = parseInt(timestamp.substring(4, 6));
        const day = parseInt(timestamp.substring(6, 8));
      
        return (day + '.' + month + '.' + year);
    }

    const clearData = () => {
        localStorage.clear();
        Object.keys(Cookies.get()).forEach(cookie => Cookies.remove(cookie));
        window.location.reload();
    };
  
    useEffect(() => {
        let lastUpdate = localStorage.getItem('last-update');
        if(lastUpdate) {
            setLastUpdate(lastUpdate);
        }

      trackPageView({
        href: window.location, // URL der aktuellen Seite
      });
    }, [location]);
    
    return (
        !settingsSet ? (
            <ion-page class="sg-body">
                <StartSettings />
            </ion-page>
        ) : (
        <ion-page class="sg-body">
            <div className="page-container">
            <MetaHelmet 
                title={t('settings')}
            />
            <div className="settings-page">
                <div
                    className="logo-container settings"
                    style={{ backgroundImage: `url("/icons/smartGastro-rund-white.svg")` }}
                >
                    <h1
                        style={{ width: '300px' }}
                        className="start-title">{t('settings')}
                    </h1>
                </div>
                <SettingsPanel />
                <div className="social-media-links">
                    <a href="https://www.instagram.com/smartgastroat/" target="_blank" className="social-media-links__item"
                    style={{ backgroundImage: `url("/icons/instagram.svg")` }}></a>
                    <a href="https://www.facebook.com/smartGastroAT/" target="_blank" className="social-media-links__item"
                    style={{ backgroundImage: `url("/icons/facebook.svg")` }}></a>
                </div>
                <div className="settings-software-information">
                    <button className="sg-button" onClick={clearData}>
                            {t('clearData')}
                    </button>
                    <div className="settings-software-information__info">
                        Web-App Version: 2.0.0<br></br>
                        {lastUpdate && (
                            <span>
                                {t('lastApiUpdate')} 
                                {parseDate(lastUpdate)}
                            </span>
                        )}
                    </div>
                </div>
            </div>
            </div>
        </ion-page>
        )
    );
}

export default pageTransition(Settings);

